<template>
  <v-row>
    <!-- The checkbox entry type -->
    <template v-if="entry.type === 'checkbox'">
      <!--<v-flex xs10>-->
      <!--<v-icon>check</v-icon>-->
      <!--<p class="subheading text-xs-left mb-0 grey&#45;&#45;text text&#45;&#45;darken-1">{{ entry.title }}</p>-->
      <!--<p class="caption text-xs-left mb-0 grey&#45;&#45;text text&#45;&#45;darken-1">{{ entry.desc }}</p>-->
      <!--</v-flex>-->

      <v-col cols="12">
        <v-row>
          <v-col
            class="align-content-start wrap"
            cols="2"
          >
            <v-checkbox
              :id="entry.key"
              v-model="entry.value"
              color="primary"
              prepend-icon="mdi-check"
              :label="entry.title"
              :hint="entry.desc"
              persistent-hint
            />
          </v-col>
          <!--<v-flex align-content-start wrap xs10>-->
          <!--<div class="subheading text-xs-left mb-0 grey&#45;&#45;text text&#45;&#45;darken-1">{{ entry.title }}</div>-->
          <!--<div class="caption text-xs-left mt-0 pt-0 grey&#45;&#45;text text&#45;&#45;darken-1">{{ entry.desc }}</div>-->
          <!--</v-flex>-->
        </v-row>
      </v-col>
    </template>

    <!-- The yes/no button toggle entry type -->
    <template v-else-if="entry.type === 'yesno'">
      <v-col cols="6">
        <v-subheader>{{ entry.name }}</v-subheader>
      </v-col>

      <v-col cols="6">
        <v-btn-toggle v-model="entry.answer">
          <v-btn
            flat
            color="secondary"
            class="px-2"
            value="yes"
          >
            Yes
          </v-btn>
          <v-btn
            flat
            color="secondary"
            class="px-2"
            value="no"
          >
            No
          </v-btn>
        </v-btn-toggle>
      </v-col>
    </template>

    <!-- The select field -->
    <template v-else-if="entry.type === 'select'">
      <v-col cols="12">
        <v-autocomplete
          :ref="entry.key"
          v-model="entry.value"
          :items="entry.values"
          :label="entry.title"
          :hint="entry.desc"
          :error-messages="entry.error != null ? [entry.error] : []"
          persistent-hint
          item-text="name"
          item-value="value"
          prepend-icon="mdi-sort-variant"
          :disabled="isDisabled()"
          :autocomplete="entry.values.length > 2"
          @change="onChange(entry)"
        />
      </v-col>
    </template>

    <!-- A scoped slot select -->
    <template v-else-if="entry.type === 'prettyselect'">
      <v-col cols="12">
        <v-autocomplete
          :ref="entry.key"
          v-model="entry.value"
          :items="entry.values"
          :label="entry.title"
          :hint="entry.desc"
          :error-messages="entry.error != null ? [entry.error] : []"
          item-text="name"
          item-value="value"
          multiple
          chips
          :autocomplete="entry.values.length > 2"
          prepend-icon="mdi-sort-variant"
          :disabled="isDisabled()"
        >
          <template
            slot="selection"
            slot-scope="data"
          >
            <v-chip
              :key="JSON.stringify(data.item)"
              :input-value="data.selected"
              close
              class="chip--select-multi"
              @update:active="data.parent.selectItem(data.item)"
            >
              <v-avatar v-if="data.item.avatar">
                <img :src="data.item.avatar">
              </v-avatar>
              {{ data.item.name }}
            </v-chip>
          </template>
          <template
            slot="item"
            slot-scope="data"
          >
            <v-list-item-avatar v-if="data.item.avatar">
              <img :src="data.item.avatar">
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ data.item.name }}</v-list-item-title>
              <v-list-item-subtitle>{{ data.item.subtitle }}</v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-autocomplete>
      </v-col>
    </template>

    <!-- A divider -->
    <template v-else-if="entry.type === 'divider'">
      <v-col cols="12">
        <div class="subheading">
          {{ entry.title }}
        </div>
        <div class="text-caption">
          {{ entry.desc }}
        </div>
      </v-col>
    </template>

    <!-- The text entry types -->
    <template v-else-if="entry.type === 'shorttext'">
      <v-col cols="12">
        <v-text-field
          :id="entry.key"
          :ref="entry.key"
          v-model="entry.value"
          :label="entry.title"
          :hint="entry.desc"
          persistent-hint
          :rules="entry.vRules"
          :mask="entry.mask"
          :required="entry.required"
          :error-messages="entry.error != null ? [entry.error] : []"
          prepend-icon="mdi-pencil"
          :disabled="isDisabled()"
          :suffix="entry.suffix"
          :prefix="entry.prefix"
          @keyup.enter.native="enterPressed"
        />
      </v-col>
    </template>
    <template v-else-if="entry.type === 'mediumtext' || entry.type === 'longtext'">
      <v-col cols="12">
        <v-textarea
          :id="entry.key"
          :ref="entry.key"
          v-model="entry.value"
          :label="entry.title"
          :hint="entry.desc"
          persistent-hint
          :rules="entry.vRules"
          :mask="entry.mask"
          :required="entry.required"
          :error-messages="entry.error != null ? [entry.error] : []"
          prepend-icon="mdi-text-short"
          :multi-line="entry.type === 'longtext'"
          :disabled="isDisabled()"
          @keyup.enter.native="enterPressed"
        />
      </v-col>
    </template>

    <!-- The password input field -->
    <template v-else-if="entry.type === 'shortpass'">
      <v-col cols="12">
        <v-text-field
          :id="entry.key"
          :ref="entry.key"
          v-model="entry.value"
          :label="entry.title"
          :hint="entry.desc"
          persistent-hint
          type="password"
          :required="entry.required"
          :error="entry.error != null"
          :error-messages="entry.error != null ? [entry.error] : []"
          prepend-icon="mdi-lock"
          :disabled="isDisabled()"
          @keyup.enter.native="enterPressed"
        />
      </v-col>
    </template>

    <!-- This is the time stamp input -->
    <template v-else-if="entry.type === 'datetime' || entry.type === 'datetime_unix'">
      <v-col cols="12">
        <p class="subheading text-left mb-0 pt-2 grey--text text--darken-1">
          {{ entry.title }}
        </p>
        <p class="text-caption text-left mb-0 grey--text text--darken-1">
          {{ entry.desc }}
        </p>
      </v-col>
      <v-col
        :cols="entry.type === 'datetime' ? 6 : 4"
      >
        <v-menu
          ref="datemenu"
          v-model="entry.dateMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          :nudge-right="40"
          min-width="290px"
          :disabled="isDisabled()"
        >
          <template #activator="{ on }">
            <v-text-field
              v-model="entry.date"
              class="pr-1"
              label="Date"
              prepend-icon="mdi-calendar"
              readonly
              hint="Click to change"
              persistent-hint
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="entry.date"
            no-title
            scrollable
            @change="setDate(entry)"
          />
        </v-menu>
      </v-col>
      <v-col
        v-if="entry.type === 'datetime'"
        cols="6"
      >
        <v-menu
          ref="timemenu"
          v-model="entry.timeMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          :nudge-right="40"
          max-width="290px"
          min-width="290px"
          :disabled="isDisabled()"
        >
          <template #activator="{ on }">
            <v-text-field
              slot="activator"
              v-model="entry.time"
              class="pl-1"
              label="Time"
              readonly
              prepend-icon="mdi-clock-outline"
              hint="Click to change"
              persistent-hint
              v-on="on"
            />
          </template>
          <v-time-picker
            v-model="entry.time"
            no-title
            format="24hr"
            @change="setTime(entry)"
          />
        </v-menu>
      </v-col>
      <template v-else>
        <v-col cols="4">
          <v-text-field
            v-model="entry.rawTime"
            class="pl-1"
            label="24h Time"
            mask="time-with-seconds"
            prepend-icon="mdi-clock-outline"
            hint="Enter as text"
            persistent-hint
            :disabled="isDisabled()"
            @input="updateFromTime(entry)"
          />
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="entry.value"
            class="pl-1"
            label="Unix Time"
            prepend-icon="mdi-clock-outline"
            hint="Enter as text"
            persistent-hint
            :disabled="isDisabled()"
            @input="updateFromUnix(entry)"
          />
        </v-col>
      </template>
    </template>

    <!-- This is the uploader dropzone component -->
    <template v-else-if="entry.type === 'upload_single'">
      <v-col cols="12">
        <p class="subheading text-left mb-0 grey--text text--darken-1">
          {{ entry.title }}
        </p>
        <p class="text-caption text-left mb-0 grey--text text--darken-1">
          {{ entry.desc }}
        </p>
      </v-col>
      <v-col cols="1">
        <v-icon>attachment</v-icon>
      </v-col>
      <v-col cols="11">
        <vue2-dropzone
          id="singleDropzone"
          ref="dropzone"
          :options="dropzoneOptions"
          @vdropzone-success="dropzoneSuccess"
          @vdropzone-error="dropzoneFailure"
        />
      </v-col>
    </template>

    <!-- This is the code element -->
    <template v-else-if="entry.type === 'code'">
      <v-col cols="12">
        <v-icon>mdi-code-tags</v-icon>
        <v-btn
          class="font-weight-bold"
          color="primary"
          small
          rounded
          @click="writeCodeToClipboard(entry.value)"
        >
          <v-icon left>
            mdi-content-copy
          </v-icon>
          Copy to clipboard
        </v-btn>
        <pre v-highlightjs><code :class="entry.lang">{{ entry.value }}</code></pre>
      </v-col>
    </template>
  </v-row>
</template>

<script>
import moment from 'moment'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import { EventBus } from '../scripts/event_bus'

export default {
  name: 'FormField',
  components: {
    vue2Dropzone: () => import('vue2-dropzone')
  },
  props: {
    entry: {
      type: Object,
      required: true,
      default () {
        return {
          key: 'default_input',
          title: 'Default Text Input',
          desc: 'This is the default short text input field',
          data: 'varchar(8)',
          type: 'shorttext',
          pattern: '',
          mask: '',
          value: null,
          required: false,
          dynamic: false,
          disabled: false
        }
      }
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    pullFocus: {
      type: Boolean,
      required: false,
      default: false
    },
    dropzoneOptions: {
      type: Object,
      required: false,
      default () {
        return {
          url: 'https://members.darkcosmos.org/cmsdb/files/uploadnote.php' + '?apikey=' + localStorage.getItem('devAPIKey'),
          timeout: 300000,
          maxFilesize: 200,
          maxFiles: 20,
          ignoreHiddenFiles: true,
          autoProcessQueue: false,
          autoQueue: true,
          addRemoveLinks: true,
          paramName: 'note_file',
          params: { note_id: '###' },
          headers: {
            'Cache-Control': ''
          }
        }
      }
    }
  },
  watch: {
    pullFocus: function (shouldFocus, wasFocused) {
      if (!wasFocused && shouldFocus) {
        this.$refs[this.entry.key].focus()
        console.log('Pulling focus to', this.entry.key)
      }
    }
  },
  mounted () {
    if (this.pullFocus) {
      this.$refs[this.entry.key].focus()
    }
  },
  methods: {
    isDisabled: function () {
      return (this.entry.disabled === true || this.disabled === true)
    },
    updateFromTime: function (entry) {
      const newUnixTime = moment(entry.rawTime + ' ' + entry.date, 'HHmmss YYYY[-]MM[-]DD').unix()

      if (newUnixTime !== entry.value) {
        entry.value = newUnixTime
      }
    },
    updateFromUnix: function (entry) {
      const unixMoment = moment.unix(entry.value)

      const newRawTime = unixMoment.format('HHmmss')
      const newDate = unixMoment.format('YYYY[-]MM[-]DD')

      if (entry.rawTime !== newRawTime) {
        entry.rawTime = newRawTime
      }

      if (entry.date !== newDate) {
        entry.date = newDate
      }
    },
    setTime: function (entry) {
      entry.timeMenu = false
      entry.value = moment(entry.date + ' ' + entry.time).unix()
    },
    setDate: function (entry) {
      entry.dateMenu = false
      if (entry.type === 'datetime') {
        entry.value = moment(entry.date + ' ' + entry.time).unix()
      } else {
        this.updateFromTime(entry)
        this.updateFromUnix(entry)
      }
    },
    onChange: function (entry) {
      // Check if the entry is dynamic, and thus should trigger a refresh
      // console.log('Field changed', entry)
      if (entry.dynamic) {
        // Delay the refresh, such that the updated info can be saved (otherwise problems happen)
        setTimeout(() => {
          this.$emit('onDynamicUpdate', entry)
        }, 100)
      }
    },
    hasFiles: function () {
      return this.$refs.dropzone.getQueuedFiles().length > 0
    },
    dropzoneUpload: function () {
      this.$refs.dropzone.processQueue()
    },
    resetDropzone: function () {
      this.$refs.dropzone.removeAllFiles()
    },
    dropzoneSuccess: function (file, response) {
      // Remove the file that was successfully uploaded
      this.$refs.dropzone.removeFile(file)
      // Send an event to the parent
      this.$emit('onUploadSuccess', response)
    },
    dropzoneFailure: function (file, message, xhr) {
      // Send the event to the parent
      this.$emit('onUploadFailure', message)
    },
    writeCodeToClipboard: function (codeToCopy) {
      // console.log('Writing', codeToCopy, 'to clipboard')

      window.navigator.clipboard.writeText(codeToCopy).then(() => {
        EventBus.$emit('info', 'Matlab code copied to clipboard successfully')
      }, () => {
        EventBus.$emit('info', 'Copy to clipboard failed... Outdated browser?')
      })
    },
    enterPressed: function () {
      this.$emit('onEnter')
    }
  }
}
</script>

<style scoped>
  p {
    padding-left: 40px;
  }
</style>
